import React from "react";

// Imports the Amplify library from 'aws-amplify' package. This is used to configure your app to interact with AWS services.
import { Amplify } from "aws-amplify";

// Imports the Authenticator and withAuthenticator components from '@aws-amplify/ui-react'.
// Authenticator is a React component that provides a ready-to-use sign-in and sign-up UI.
// withAuthenticator is a higher-order component that wraps your app component to enforce authentication.
import { Authenticator, withAuthenticator } from "@aws-amplify/ui-react";

// Imports the default styles for the Amplify UI components. This line ensures that the authenticator looks nice out of the box.
import "@aws-amplify/ui-react/styles.css";

// Imports the awsExports configuration file generated by the Amplify CLI. This file contains the AWS service configurations (like Cognito, AppSync, etc.) specific to your project.
import awsExports from "../../aws-exports";

// Imports the Quiz component from Quiz.js for use in this file.
import Quiz from "./Quiz";
import BannerImage from "../../Assets/logo2.svg";

import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import comingSoon from "../../Assets/Group 62.svg";

import "bootstrap/dist/css/bootstrap.min.css";
import Purchase from "./Purchase";
import PurchaseTP from "./PurchaseTP";
// Configures the Amplify library with the settings from aws-exports.js, which includes all the AWS service configurations for this project.
Amplify.configure(awsExports);

const Account = () => {
  return (
    <div className="account-wrapper">
      <br />
      <br />
      <br />
      <br />
      <br />
      <div className="about-wrapper">
        <div className="about-us-container">
          <div className="rowC">
            <div className="form">
              <div className="Auth-wrapper">
                <Authenticator>
                  {({ signOut, user }) => (
                    <main>
                      <header className="account-header">
                        <div className="logged-in">
                          {/* Quiz Component */}
                          <h1>Welcome {user?.signInDetails?.loginId}</h1>
                          <PurchaseTP />

                          {/* Sign Out Button */}

                          <button
                            className="about-button-2"
                            onClick={signOut}
                            style={{
                              margin: "20px",
                              fontSize: "0.8rem",
                              padding: "5px 10px",
                              marginTop: "20px",
                            }}
                          >
                            Sign Out
                          </button>
                        </div>
                      </header>
                    </main>
                  )}
                </Authenticator>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Account;
